@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

body.hover-cursor-on * {
  cursor: none !important;
}

body.return-cursor-on * {
  cursor: none !important;
}
.prose {
  max-width: 100% !important;
  color: #333 !important;
}
img,
video {
  margin: 0px !important;
}
.header-gradient {
  background: linear-gradient(180deg, #000 10%, rgba(0, 0, 0, 0) 100%);
  min-height: 245px;
}

#hoverCursor {
  background: url('/images/drag.png') no-repeat center center;
  opacity: 0;
  background-size: cover;
  height: 96px;
  width: 96px;
  position: fixed;
  top: -48px;
  left: -48px;
  z-index: 1000;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: opacity 150ms ease-in-out;
}
.map-section .active-path {
  fill: #c1306f !important;
  stroke: #c1306f !important;
}
.map-section .active-path-number {
  fill: #fff !important;
}
.map-section .active-circle {
  fill: #c1306f !important;
  stroke: #c1306f !important;
}
.map-section .active-circle-mobile {
  fill: #c1306f !important;
  stroke: #c1306f !important;
}
.map-section .active-path-mobile {
  fill: #fff !important;
}
.map-section .hover-path {
  cursor: pointer;
  transition: all 0.4s;
}
::marker {
  color: #333 !important;
}

.svg-container {
  transition: transform 0.5s ease-in-out;
}
[dir='rtl'] .external-link-white::after,
[dir='rtl'] .external-link-white-2::after,
[dir='rtl'] .external-link::after {
  margin-right: 0.5rem;
  rotate: -90deg;
}

[dir='ltr'] .external-link-white-2::after,
[dir='ltr'] .external-link-white::after,
[dir='ltr'] .external-link::after {
  margin-left: 0.5rem;
}
.external-link-white:hover::after {
  background-image: url('/images/external-icon.png');
}
.external-link-white-2:hover::after {
  background-image: url('/images/external-link-white.png');
}
.external-link:hover::after {
  background-image: url('/images/external-link-white.png');
}
.external-link-white::after {
  background-image: url('/images/external-link-white.png');
}
.external-link-white-2::after {
  background-image: url('/images/external-link-white.png');
}
.external-link::after {
  background-image: url('/images/external-icon.png');
}
.external-link::after,
.external-link-white::after,
.external-link-white-2::after {
  content: '';
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background-size: cover;
  transition: all 0.3s;
}
.overlay-card {
  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
}
.dir-ltr {
  direction: ltr;
}

@media (max-width: 991px) {
  .location-map-ar_svg__map-gradient,
  .location-map_svg__map-gradient {
    display: none;
  }
}


#map-with-cards-2 .swiper-vertical > .swiper-scrollbar,
#map-with-cards-2 .swiper-scrollbar.swiper-scrollbar-vertical {
  right: var(--swiper-scrollbar-right, 0);
  width: var(--swiper-scrollbar-size, 8px);
  background: none;
}

#map-with-cards-2 .swiper-scrollbar-drag {
  background: #E5E5E5;
}

@media (max-width: 1280px) {
  .home-banner div {
    height: auto;
  }
  .home-banner div canvas {
    max-width: 300px;
    max-height: 300px;
  }
}

.html-content > div ul ul {
  list-style-type: circle;
}

.html-content > div ul ul ul {
  list-style-type: square;
}

.f-cc-pref-open .cmp-body {
  z-index: 99999;
  background: rgba(0,0,0,.8)
}

.cc-compliance .cc-btn,
.f-cc-pref-open .cmp-btn-wrapper .cmp-save-btn,
.f-cc-pref-open .cmp-horizontal-tabs__nav ul li a span {
  text-transform: uppercase;
}

.f-cc-pref-open .cmp-tabs__content {
  background: #000;
}

.f-cc-pref-open .cmp-accordion {
  margin-bottom: 15px !important;
}

.f-cc-pref-open .cmp-accordion__content.purpose-details ul {
  list-style-type: disc;
}

.f-cc-pref-open .cmp-accordion__content.purpose-details ul li::marker {
  color: #fff !important;
}

.f-cc-pref-open .cmp-switch input:focus+.cmp-switch__slider {
  margin: 0;
}

.f-cc-pref-open .cmp-accordion__header {
  padding: 11px 0 !important;
}

.f-cc-pref-open .cmp-tabs__nav .cmp-mobile-dropdown,
.f-cc-pref-open .cmp-tabs__nav ul li.is-active,
.f-cc-pref-open .cmp-modal__footer .cmp-save-btn,
.f-cc-pref-open .cmp-modal__footer .cmp-decline-btn {
  border-radius: 2px;
}

.f-cc-pref-open .cmp-accordion__content>.cmp-accordion,
.f-cc-pref-open .cmp-tabs__opt-out.active,
.f-cc-pref-open .cmp-tabs__gpc-message.active {
  border-width: 1px;
  border-color: #46444d;
}

.f-cc-pref-open .cmp-accordion__content>.cmp-accordion {
  padding-bottom: 0;
  padding-top: 0;
}

.f-cc-pref-open .cmp-accordion__content {
  padding: 0 !important;
}

.f-cc-pref-open .cmp-accordion__sub-title {
  margin-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.f-cc-pref-open .cmp-switch input:checked+.cmp-switch__slider:after,
.f-cc-pref-open .cmp-switch__slider:after {
  background: #fff;
}

.f-cc-pref-open .cmp-switch input:focus+.cmp-switch__slider,
.cmp-body :focus,
.cmp-body :focus-visible,
.cc-window :focus,
.cc-window :focus-visible,
.cc-window.cc-banner :focus, 
.cc-window.cc-banner :focus-visible {
  outline: none !important;
}

.cmp-tabs__opt-out.active,
.cmp-horizontal-tabs__nav ul li a span,
.cc-banner .cc-message a,
.cc-banner .cc-message p,
.f-cc-pref-open .cmp-body .cmp-link-btn,
.f-cc-pref-open .cmp-body.cmp-multi-level .cmp-link-btn,
.f-cc-pref-open .cmp-modal__sub-title {
  font-size: .875em !important;
  line-height: 1.5 !important;
}

.cmp-tabs__opt-out.active {
  font-weight: inherit !important;
}

.cc-banner .cc-message p:not(:last-child),
.f-cc-pref-open .cmp-modal__sub-title p:not(:last-child) {
  margin-bottom: 16px;
}

.f-cc-pref-open .cmp-tabs__nav .cmp-mobile-dropdown .cmp-selected-category,
.f-cc-pref-open .cmp-modal__sub-title p span,
.f-cc-pref-open .cmp-horizontal-tabs__nav ul li.is-active span,
.f-cc-pref-open .cmp-tabs__nav ul li.is-active {
  color: #fff !important;
}

.f-cc-pref-open .cmp-modal__sub-title p,
.f-cc-pref-open .cmp-modal__sub-title p span,
.f-cc-pref-open .cmp-modal__sub-title p span span {
  font-family: inherit !important;
}

.f-cc-pref-open .cmp-tabs {
  margin-top: 10px !important;
}

.f-cc-pref-open .cmp-expand-nav-icon path,
.f-cc-pref-open .cmp-tabs__nav .cmp-mobile-dropdown .cmp-check-icon,
.f-cc-pref-open .cmp-switch input:checked+.cmp-switch__slider svg,
.f-cc-pref-open li.is-active .cmp-check-icon {
  fill: #fff !important;
}

@media (min-width: 768px) {
  .f-cc-pref-open .cmp-tabs .cmp-tabs__nav ul li.is-active a {
    color: #fff !important; 
  }
  .f-cc-pref-open .cmp-modal__logo {
    width: 64px;
    height: 72px;
    min-width: auto;
  }
  .lang-ar .cmp-modal__close {
    right: auto;
    left: 32px;
  }
}

@media (min-width: 1024px) {
  .f-cc-pref-open .cmp-body {
    background: rgba(0,0,0,.4);
  }
}

@media (min-width: 1340px) {
  .cmp-modal {
    max-width: 1024px;
  }
  .cc-banner .cc-message p {
    font-size: 14px !important;
  }
}

@media (min-width: 1920px) {
  .cc-window.cc-banner {
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media (max-width: 1023px) {
  .f-cc-pref-open .cmp-tabs {
    padding-top: 10px;
  }
  .cmp-modal__title {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .cc-banner .cc-compliance {
    padding-top: 0!important;
  }
  .cmp-horizontal-tabs__nav ul li a {
    padding: 5px 12px;
  }
  .cmp-modal__title {
    font-size: 20px;
  }
  .cc-pref-open .cmp-modal__sub-title {
    padding: 16px 18px;
  }
  /* .cmp-modal {
    max-height: calc(100vh - 70px);
  } */
  .cmp-modal__logo {
    width: 50px;
    height: 56px;
    min-width: 50px;
  }
  .f-cc-pref-open .cmp-accordion__header {
    padding: 6px 0 !important;
  }
  .cc-banner .cc-message {
    padding: 28px 0 !important;
  }
  .cmp-switch {
    width: 50px !important;
  }

  .cmp-modal__header {
    margin-bottom: 5px;
  }

  .cmp-modal__body {
    padding-bottom: 15px;
  }

}

.cmp-body .cmp-switch {
  width: 48px;
}

.lang-ar .cc-banner,
.f-cc-pref-open.lang-ar .cmp-body * {
  font-family: GEFlow, Bliss2, sans-serif;
}

.f-cc-pref-open.lang-ar .cmp-cookie-info .cmp-cookie-head {
  text-align: right;
}

.f-cc-pref-open .cmp-tabs__content {
  overlay: visible;
  max-height: none;
}

.lang-ar .cmp-tabs__nav .cmp-mobile-dropdown {
  padding: 8px 28px 8px 8px
}

.lang-ar .cmp-tabs__nav .cmp-mobile-dropdown svg {
  left: auto;
  right: 5px;
}

.lang-ar .cc-btn {
  padding: 0 12px;
}

.cookies-list {font-size: clamp(0.875rem, 0.825rem + 0.25vw, 1.125rem);}
.cookies-list ol { list-style-type: none;margin-top: 0;padding-inline-start: 35px; }
.cookies-list ol > li { text-indent: -40px;padding: 0; font-family: GEFlow, Bliss2, sans-serif; }
.cookies-list ol > li li { text-indent: 0; }
.cookies-list ol > li > span {
  display: inline-block;
  min-width: 35px;
}

.cookies-table-container {
  overflow-x: auto;
}

html[dir=ltr] .cookies-list ol > li > span {
  padding-right: 8px;
  text-align: right;
}

html[dir=rtl] .cookies-list ol > li > span {
  padding-left: 8px;
  text-align: left;
}

html[dir='rtl'] .cookies-list {
  font-family: GEFlow, Bliss2, sans-serif;
}
